<template>
  <section id="card-text-alignment">
    <section id="search-section">
      <b-row>
        <b-col md="12">
          <b-card>
            <Search/>
          </b-card>
        </b-col>
      </b-row>
    </section>

    <StockEntryList/>
  </section>
</template>

<script>
import Search from '@/views/stock/entry/Search'
import {mapGetters} from 'vuex'
import StockEntryList from '@/views/stock/entry/StockEntryList'

export default {
  name: 'StokEntryHome',
  components: {Search, StockEntryList},
  computed: {
    ...mapGetters('stockEntry', ['_stocks']),
  },
}
</script>

<style scoped>

</style>
