<template>
  <b-overlay
      :show="show"
      rounded="sm"
  >
    <b-form @submit.prevent>
      <b-row>
        <b-col md="11">
          <b-row>
            <b-col md="2">
              <b-form-group label="Tedarikçi">
                <v-select
                    v-model="addProduct.supplier_id"
                    label="name"
                    :options="_suppliers"
                    :reduce="supplier => supplier.id"
                    class="select-size-xs"
                />
              </b-form-group>
            </b-col>
            <b-col md="1">
              <b-form-group label="Stok İade">
                <b-form-radio
                    v-model="addProduct.stock_return"
                    value="9"
                    class="custom-control-success"
                >
                  Stok
                </b-form-radio>
                <b-form-radio
                    v-model="addProduct.stock_return"
                    value="27"
                    class="custom-control-danger"
                >
                  İade
                </b-form-radio>
                <b-form-radio
                    v-model="addProduct.stock_return"
                    value="50"
                    class="custom-control-primary"
                >
                  Temin
                </b-form-radio>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Raf Adı">
                <b-form-input
                    v-model="addProduct.shelve_name"
                    type="text"
                    size="xs"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Ürün Barcode">
                <b-form-input
                    v-model="addProduct.search_barcode"
                    type="text"
                    size="xs"
                />
              </b-form-group>
            </b-col>
            <b-col md="1">
              <b-form-group label="Stok Adedi">
                <b-form-input
                    v-model="addProduct.quantity"
                    type="number"
                    size="xs"
                />
              </b-form-group>
            </b-col>
            <b-col md="1">
              <b-form-group label="Piyasa Fiyatı">
                <b-form-input
                    v-model="addProduct.market_price"
                    type="number"
                    size="xs"
                    @keyup.enter="calculate('market_price')"
                    @blur="calculate('market_price')"
                />
              </b-form-group>
            </b-col>
            <b-col md="1">
              <b-form-group label="İskonto">
                <b-form-input
                    v-model="addProduct.discount"
                    type="number"
                    size="xs"
                    @keyup.enter="calculate('discount')"
                    @blur="calculate('discount')"
                />
              </b-form-group>
            </b-col>
            <b-col md="1">
              <b-form-group label="KDV'li Fiyatı">
                <b-form-input
                    v-model="addProduct.price"
                    type="number"
                    size="xs"
                    @keyup.enter="calculate('price')"
                    @blur="calculate('price')"
                />
                <b-badge
                    v-if="calculatedError"
                    variant="error"
                >
                  {{ calculatedError }}
                </b-badge>
              </b-form-group>
            </b-col>
            <b-col md="1">
              <b-form-group label="KDV'siz Fiyatı">
                <b-form-input
                    v-model="addProduct.vat_free_price"
                    type="number"
                    size="xs"
                    @keyup.enter="calculate('vat_free_price')"
                    @blur="calculate('vat_free_price')"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="1">
          <b-form-group label="Yönetim">
            <b-button-group size="sm">
              <!-- Butonlar Buraya Gelecek -->
              <!--type="submit"-->
              <b-button
                  variant="primary"
                  @click="addNewProduct"
              >
                Ekle
              </b-button>
              <!-- Butonlar Buraya Gelecek -->
            </b-button-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-overlay>
</template>

<script>
import vSelect from 'vue-select'
import {mapGetters} from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'Search',
  components: {vSelect},
  data: () => ({
    show: false,
    calculatedError: '',
    lastBarcode: '',
    addProduct: {
      id: null,
      supplier_id: null,
      stock_return: null,
      shelve_name: null,
      search_barcode: null,
      quantity: 1,
      market_price: 0,
      discount: 0,
      price: 0,
      vat_free_price: 0,
      product: null,
    },
  }),
  computed: {
    ...mapGetters('supplier', ['_suppliers']),
  },
  methods: {
    addNewProduct() {
      this.show = true
      if (!(this.addProduct.supplier_id && this.addProduct.stock_return && this.addProduct.shelve_name && this.addProduct.search_barcode && this.addProduct.quantity)) {
        alert('Veriler boş olmamaz')
        this.show = false
        return
      }
      this.$store.dispatch('stockEntry/addNewStock', this.addProduct)
          .then(res => {
            if (res.product) {
              this.$swal({
                title: 'Başarılı',
                text: 'Ürün Bulundu',
                icon: 'success',
                timer: 1000,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            } else {
              this.$swal({
                title: 'Dikkat',
                text: 'Ürün Bulunumadı',
                icon: 'error',
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
            this.show = false
            this.addProduct.id = null
            this.addProduct.search_barcode = null
            this.addProduct.quantity = 1
            this.addProduct.market_price = 0
            this.addProduct.discount = 0
            this.addProduct.price = 0
            this.addProduct.vat_free_price = 0
            this.addProduct.product = null
            this.$toast({
              component: ToastificationContent,
              props: res,
            })
          })
    },
    calculate(item) {
      this.calculatedError = ''
      if ((item == 'market_price' || item == 'discount') && this.addProduct.discount > 0 && this.addProduct.market_price > 0) {
        console.log('ee')
        this.addProduct.price = this.addProduct.market_price - (this.addProduct.market_price * this.addProduct.discount / 100)
      } else if (item == 'price' && this.addProduct.market_price > 0) {
        console.log('rr')
        this.addProduct.discount = ((this.addProduct.market_price - this.addProduct.price) / this.addProduct.market_price * 100)
      } else {
        console.log('zz')
        this.calculatedError = 'Bir Hata Var'
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
