<template>
  <b-row>
    <b-col cols="12" class="custom-search d-flex justify-content-end">
      <b-button variant="primary" size="sm" @click="saveProduct(_stocks)">Tümünü Kaydet</b-button>
    </b-col>
    <b-col cols="12">
      <b-table
          striped
          hover
          responsive
          :items="_stocks"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
      >

        <template #cell(supplier)="data">
          <b-badge variant="success">{{ data.item.supplier_id }}</b-badge>
          <br>
          <b-badge v-if="data.item.stock_return==9">Stok</b-badge>
          <b-badge v-if="data.item.stock_return==27">İade</b-badge>
          <b-badge v-if="data.item.stock_return==50">Temin</b-badge>
        </template>


        <template #cell(barcode)="data">
          <b-badge>{{ data.item.search_barcode }}</b-badge>
          <br>
          <b-badge variant="dark" v-if="data.item.product">{{ data.item.product.barcode }}</b-badge>

          <div v-if="data.item.product">
            <div v-for="(orderProduct,opIndex) in data.item.product.order_products" :key="opIndex">
              <b-badge variant="danger"> Sipariş: {{ orderProduct.count }}</b-badge>
              <b-badge variant="danger"> Adet: {{ orderProduct.sum }}</b-badge>
            </div>
          </div>
        </template>

        <template #cell(image)="data">
          <ProductImages
              v-if="data.item.product"
              prefix="https://api.kitapfirsatlari.com/"
              :images="data.item.product.images"/>
        </template>

        <template #cell(product)="data">
          <b-badge v-if="data.item.product">{{ data.item.product.id }}</b-badge>
          <br>
          <b-badge v-if="data.item.product">{{ data.item.product.name }}</b-badge>
        </template>

        <template #cell(quantity)="data">
          <b-form-input type="text" size="xs" v-model="data.item.quantity"/>
        </template>

        <template #cell(market_price)="data">
          <b-form-input type="text" size="xs" v-model="data.item.market_price"/>
        </template>

        <template #cell(price)="data">
          <b-form-input type="text" size="xs" v-model="data.item.price"/>
        </template>

        <template #cell(vat_free_price)="data">
          <b-form-input type="text" size="xs" v-model="data.item.vat_free_price"/>
        </template>

        <template #cell(discount)="data">
          <b-form-input type="text" size="xs" v-model="data.item.discount"/>
        </template>

        <template #cell(action)="data">

          <b-badge variant="success" v-if="data.item.id">Kayıt No: {{ data.item.id }}</b-badge>

          <br>
          <b-button variant="primary" @click="saveProduct([data.item])">Kaydet</b-button>
        </template>

      </b-table>
    </b-col>
  </b-row>
</template>

<script>
import {mapGetters} from "vuex";
import ProductImages from "@/components/ProductImages";
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BPagination,

} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BPagination,
    ProductImages
  },
  data() {
    return {
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      fields: [
        {key: 'supplier', label: 'Tedarikçi'},
        {key: 'shelve_name', label: 'Raf Ado', sortable: true},
        {key: 'barcode', label: 'Barkod'},
        {key: 'product', label: 'Ürün Bilgisi'},
        {key: 'image', label: 'Resim'},
        {key: 'quantity', label: 'Adet', sortable: true},
        {key: 'market_price', label: 'Piyasa', sortable: true},
        {key: 'price', label: "KDV'li Alış", sortable: true},
        {key: 'vat_free_price', label: "KDV'siz Alış", sortable: true},
        {key: 'discount', label: "İskonto", sortable: true},
        {key: 'action', label: "Yönetim"},
      ],
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
        {
          1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
        }],
    }
  },
  computed: {
    ...mapGetters("stockEntry", ["_stocks"]),

  },
  methods: {
    saveProduct(items) {
      this.$store.dispatch('stockEntry/saveNewStocks', items);
    }
  },
}
</script>
